import React, { useState } from 'react'
import { FlexContainer, Button } from '../elements'
import styled from 'styled-components'
import { getImage } from '../imageContainers'
import { subscribe } from '../CMS/Home'


const Title = styled.h1`
  font-size: 3.5rem;
  color: ${props => props.theme.colors.yellowMelior};
  font-family: ${props => props.theme.fonts['Myriadpro-bold']};
  text-transform: capitalize;
  letter-spacing: ${props => props.lang === "ar"? 0 : "2px"};
  margin: 20px;
  text-align: center;
`

const Text = styled.h3`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts['Helvetica-light']};
  text-transform: capitalize;
`
const ImproveNowText = styled(Text)`
  color: #999;
`
const Email = styled.input`
  background: transparent;
  outline: none;
  font-size: 1rem;
  padding: 15px;
  height: 64px;
  border: 1px solid #444;
  border-radius: ${props => props.lang === "ar"? "0px 4px 4px 0px" : "4px 0px 0px 4px"};
  width: 70%;
  border-right: ${props => props.lang === "ar"? "1px solid black" : 0};
  border-left:  ${props => props.lang === "ar"? 0 : "1px solid black"};
  &:focus {
    border: 1px solid ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 425px) {
    border: 1px solid #444;
    width: 90%;
    border-radius: 4px;
  }
`

const Submit = styled(Button)`
  border-radius:  ${props => props.lang === "ar"? "4px 0px 0px 4px": "0px 4px 4px 0px" };
  margin: 0px;
  padding: 10px;
  font-size: 1.3rem;
  height: 64px;
  text-align: center;
  font-family: ${props => props.theme.fonts['Helvetica-MediumCond']};
  transition: 0.5s ease-in-out;
  border: 0;

  &:hover {
    background: transparent;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.yellowMelior};
    color: ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 425px) {
    border-radius: 4px;
    width: 90%;
    margin: 10px;
    padding: 15px;
  }
`
  
const SetUpMelior = ({ className, lang }) => {
  const [email, setEmail] = useState('')
  const onSubmit = e => {
    e.preventDefault()
    const form = document.forms['email-form']
    const scriptURL =
      'https://script.google.com/macros/s/AKfycbxsH2AWU6dmzLewyi3VrQY_82pfzX1gwBddZJWkoiEOiAwkxb8/exec'

    fetch(scriptURL, { method: 'POST', body: new FormData(form) })
    setEmail('')
  }
  return (
    <FlexContainer className={className} alignItems="center" direction="column">
      <div className="iconPosition">{getImage('melior-icon.png')}</div>
      <Title dir={lang === "ar"? "rtl" : "ltr"} lang={lang}>{subscribe[lang].title}</Title>
      <ImproveNowText>{subscribe[lang].subtitle}</ImproveNowText>
      <form className="form" onSubmit={onSubmit} name="email-form">
        <FlexContainer
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          dir={lang === "ar"? "rtl": "auto"}
        >
          <Email
            type="email"
            name="email"
            placeholder={subscribe[lang].placeholder}
            onChange={e => setEmail(e.target.value)}
            value={email}
            lang={lang}
          />
          <Submit type="submit" lang={lang}>{subscribe[lang].button}</Submit>
        </FlexContainer>
      </form>
    </FlexContainer>
  )
}

export default styled(SetUpMelior)`
  .form {
    width: 100%;
    max-width: 800px;
  }
  .iconPosition {
    position: relative;
    left: 12%;
    top: 26px;
  }
  .padding {
    padding: 0px 15px;
    width: 100%;
    text-align: center;
  }
  .subscribe_now {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    width: 90%;
    border-radius: 4px;
    letter-spacing: ${props => props.lang === "ar"? 0 : "1.7px"};
    margin: 50px 10px;
    padding: 20px;
    font-size: 1.8rem;
    font-family: ${props => props.theme.fonts['Myriadpro-bold']};
    transition: 0.5s ease-in-out;

    &:hover {
      background: ${props => props.theme.colors.yellowMeliorGentle};
      color: ${props => props.theme.colors.black};
      transform: scale(1.15);
    }
    @media (max-width: 425px) {
      width: 100%;
      margin: 5px 0px;
      padding: 15px;
      font-size: 1.4rem;
    }
  }
`
