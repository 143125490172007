import React from 'react'
import { FlexContainer } from '../elements'
import { getImage } from '../imageContainers'
import styled from 'styled-components'
import { Parallax } from 'react-scroll-parallax'

const Title = styled.h3`
  color: ${props => props.theme.colors.yellowMelior};
  font-family: ${props => props.theme.fonts['BigJohm']};
  font-size: 1.8rem;
  font-weight: ${props => props.lang === "ar"? "bold" : "normal"};
  text-transform: capitalize;
  margin: 20px 0px;
  z-index: 2;

  @media (max-width: 375px) {
    font-size: 1.5rem;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts['Helvetica-light']};
  font-size: 1.5rem;
  line-height: 29px;
  margin-bottom: 10px;
  z-index: 2;

  @media (max-width: 375px) {
    font-size: 1.3rem;
  }
`

const TextBubble = styled(FlexContainer)`
  border-radius: 50px;
  padding: 3rem 2.7rem;
  width: 40%;
  z-index: 2;
  word-spacing: ${props => props.lang === "ar"? "3px" : "0"};
  @media (max-width: 768px) {
    text-align: center;
    padding: 1rem;
    width: 90%;
  }
`

const HomePageProdDetails = ({
  className,
  image,
  title,
  text,
  text2,
  imagePosition,
  children,
  parallex,
  lang
}) => {
  return (
    <FlexContainer className={className} flexWrap="wrap" alignItems="center">
      <FlexContainer
        className="smallScreeFullScreen"
        width="50%"
        minWidth="300px"
        minHeight="400px"
        order={imagePosition === 'right' ? 1 : 0}
      >
        <div className="image">
          <Parallax y={parallex}>{getImage(image)}</Parallax>
        </div>
      </FlexContainer>
      <TextBubble direction="column" lang={lang}>
        <Title lang={lang}>{title}</Title>
        <Text>{text}</Text>
        <Text>{text2}</Text>
      </TextBubble>
      {children}
    </FlexContainer>
  )
}

export default styled(HomePageProdDetails)`
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }

  .image {
    width: 100%;
    height: auto;
  }
  .smallScreeFullScreen {
    @media (max-width: 768px) {
      width: 90%;
      justify-content: center;
      margin: auto;
    }
  }
`
