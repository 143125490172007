import React from 'react'
import { FlexContainer } from '../elements'
import { getImage } from '../imageContainers'
import styled from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import { SECTION2 } from '../CMS/Home'

const ServiceTitle = styled.h2`
  font-family: ${props => props.theme.fonts['MyriadProRegular']};
  color: ${props => props.theme.colors.black};
  text-align: center;
  z-index: 2;
  margin-bottom: 0.7rem;
`
const ServiceDescription = styled.p`
  z-index: 2;
  text-align: center;
  font-family: ${props => props.theme.fonts['Helvetica-light']};
  color: #666;
`
const ImageWithText = (imageName, title, description, parallax) => (
  <FlexContainer direction="column" minWidth="300px" flex="3" maxWidth="300px" justifyContent="flex-start">
    <div className="imageContainer">
        <Parallax y={parallax} >
          {getImage(imageName)}
          </Parallax>
    </div>
    <ServiceTitle>{title}</ServiceTitle>
    <ServiceDescription>{description}</ServiceDescription>
  </FlexContainer>
)
const HomePageServices = ({ className, lang }) => {
  return (
    <div 
      className={className}
    >

    <FlexContainer
      className= "row"
      justifyContent="space-between"
      direction={lang === "ar"? "row-reverse" : "row"}
      flexWrap="wrap"
      >
      {ImageWithText(
         SECTION2[lang][0].img,
         SECTION2[lang][0].title,
         SECTION2[lang][0].description,
        ['-30px', '20px']
        )}
      {ImageWithText(
        SECTION2[lang][1].img ,
        SECTION2[lang][1].title,
        SECTION2[lang][1].description,
        ['-20px', '30px']
        )}
      {ImageWithText(
        SECTION2[lang][2].img,
        SECTION2[lang][2].title,
        SECTION2[lang][2].description,
        ['-30px', '20px']
        )}
    </FlexContainer>
  </div>
  )
}

export default styled(HomePageServices)`
  .imageContainer {
    height: 80px;
    margin: 0 auto;
    z-index: -1;
  }
  @media (max-width: 960px) {
    .row{
      justify-content: space-evenly;
    }
  }
`
