export const SECTION1 = {
  en: {
    title: 'Connect With Your Patients Now',
    description: [
      'Listen to their complaints and manage your performance through their reviews',
      'Improve now',
    ],
    buttons: ['Try Demo', 'See pricing'],
  },
  ar: {
    title: 'تواصل مع مرضاك بسهولة',
    description: [
      'حسن أداء فريقك من خلال الإستخدام الذكي لآراء عملائك',
      'جودة خدمة مبنية على احتياجات المريض',
    ],
    buttons: ['جرب العرض', 'الأسعار'],
  },
}

export const SECTION2 = {
  en: [
    {
      title: 'Monitor your progress',
      img: 'service1-monitor.png',
      description: [
        'View your patients’ overall satisfaction, effectiveness of your marketing strategy and ratings of hospitality, comfort and medical services among others.',
      ],
    },
    {
      title: 'Track your complaints',
      img: 'service2-track.png',
      description: [
        'Allow open communication with your patients. Take action on the issues you receive and easily track their status on the dashboard.',
      ],
    },
    {
      title: 'Improve now',
      img: 'service3-improve.png',
      description: [
        'Focus your efforts on improving and let melior handle the data collection, sorting and analysis.',
      ],
    },
  ],
  ar: [
    {
      title: 'تابع أداء فريقك',
      img: 'service1-monitor.png',
      description: [
        'يتيح لك النظام قياس مدى رضاء المريض عن الخدمة كما يمكنك تقييم مدى نجاح استراتيجيتك التسويقية',
      ],
    },
    {
      title: 'تتبع الشكاوى الخاصة بك',
      img: 'service2-track.png',
      description: [
        'شكاوى المرضى تصل لك بشكل مباشر وفوري بما يتيح لك الإستجابة لأي مشكلات وإتخاذ قرارات لحلها بناء على معلومات دقيقة',
      ],
    },
    {
      title: 'طور خدماتك',
      img: 'service3-improve.png',
      description: [
        'ركز جهودك على تحسين الخدمة ودع مليور يتولى جمع البيانات وترتيبها وتحليلها',
      ],
    },
  ],
}

export const SECTION3 = {
  en: {
    title: 'Beat Survey Fatigue',
    img: 'tablet-app-example-extra.png',
    description: [
      `Patients can submit their reviews and complaints in only 30 seconds.
    `,
      `The simple and slick design of the survey ensures your patients don’t give up on the process halfway through.`,
    ],
  },
  ar: {
    title: 'تفاعل قوي',
    img: 'tablet-app-example-extra.png',
    description: [
      `خلال ٣٠ ثانية فقط يكون المريض قد ترك رأيه بشكل كامل من خلال أسئلة بسيطة وتفاعلية تراعي إنشغال المريض وضيق وقته`,
      ``,
    ],
  },
}

export const SECTION4 = {
  en: {
    title: 'Make Decisions Faster And Improve Your Patients Experience',
    img: 'melior-dashboard-extra.png',
    description: [
      'Track and view real time data, Filter and View progress in an interactive dashboard.',
    ],
  },
  ar: {
    title: 'اتخذ قرارات أسرع وحسًن تجربة مرضاك',
    img: 'melior-dashboard-extra.png',
    description: [
      'تتبع التقييمات لحظة بلحظة. رتب وأعرض البيانات التي تم تحليلها في لوحة معلومات تفاعلية',
    ],
  },
}

export const subscribe = {
  en: {
    title: 'Set Up Melior Today',
    subtitle: 'Improve Now',
    placeholder: 'Your email address',
    button: 'Get in Touch',
  },
  ar: {
    title: 'اشترك اليوم',
    subtitle: 'تطور الآن',
    placeholder: 'اكتب عنوان بريدك الإلكتروني',
    button: 'تواصل معنا',
  },
}

/*title: "",
    description: "" */
