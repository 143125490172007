import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { FlexContainer, ButtonLink, FilledCircle } from '../elements'
import styled from 'styled-components'
import HomePageServices from '../components/homePageServices'
import HomePageProdDetails from '../components/homePageProdDetails'
import SetUpMelior from '../components/setUpMelior'
import homePageMainAnimated from '../images/home-page-main-animated.gif'
import { ParallaxProvider } from 'react-scroll-parallax'
import { SECTION1, SECTION3, SECTION4 } from '../CMS/Home'

const MainText = styled.h1`
  color: ${props => props.theme.colors.yellowMelior};
  font-size: 3rem;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

const Text = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`
const ImproveNowText = styled(Text)`
  color: #999;
`
const ActionButton = styled(ButtonLink)`
  font-family: ${props => props.theme.fonts['Helvetica-MediumCond']};
  font-size: 1.3rem;
  margin-left: 0px;
  &:hover {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.yellowMelior};
    transition: 0.2s;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 768px) {
    margin: 10px 0px;
    width: 100%;
  }
`
const LinkButton = styled(Link)`
  font-family: ${props => props.theme.fonts['Helvetica-MediumCond']};
  text-align: center;
  height: min-content;
  margin: 10px;
  padding: 10px 30px;
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.yellowMelior};
  border-radius: 4px;
  font-size: 1.3rem;
  &:hover {
    color: ${props => props.theme.colors.yellowMelior};
    transition: 0.2s;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`
const IndexPage = ({ className }) => {
  const lang = 'en'

  return (
    <Layout lang={lang}>
      <SEO title="Home" />
      <ParallaxProvider>
        <FlexContainer
          className={className}
          direction="column"
          margin="0px 0px 0px 0px"
        >
          <FlexContainer
            justifyContent="space-between"
            className="main"
            alignItems="center"
            flexWrap="wrap"
            padding="4rem 0"
            margin="0 0 2rem 0"
            dir={lang === 'ar' ? 'rtl' : 'auto'}
          >
            <FlexContainer
              direction="column"
              width="50%"
              className="textPart smallScreenFullScreen"
            >
              <MainText>{SECTION1[lang].title}</MainText>
              <Text>{SECTION1[lang].description[0]}</Text>
              <ImproveNowText>{SECTION1[lang].description[1]}</ImproveNowText>
              <FlexContainer className="actions" flexWrap="wrap">
                <ActionButton to={`/${lang}/product-tour`}>
                  {SECTION1[lang].buttons[0]}
                </ActionButton>
                <LinkButton to={`/${lang}/pricing`}>
                  {SECTION1[lang].buttons[1]}
                </LinkButton>
              </FlexContainer>
            </FlexContainer>
            <div
              className="img smallScreenFullScreen"
              style={lang === 'ar' ? { transform: 'rotateY(180deg)' } : null}
            >
              <div className="imgXandO">+</div>
              <div className="imgXandO1">o</div>
              <img
                src={homePageMainAnimated}
                alt="Melior dashboard and Android application"
              />
            </div>
          </FlexContainer>
          <div className="section">
            <HomePageServices
              lang={lang}
              dir={lang === 'ar' ? 'rtl' : 'auto'}
            />
          </div>
          <div className="section" dir={lang === 'ar' ? 'rtl' : 'auto'}>
            <FilledCircle
              top="13rem"
              right={lang === 'ar' ? 'unset' : '-1rem'}
              left={lang === 'ar' ? '-1rem' : 'unset'}
              small_top="6rem"
              small_right={lang === 'ar' ? 'unset' : '2rem'}
              small_left={lang === 'ar' ? '2rem' : 'unset'}
              size="150px"
              duration="5s"
            />
            <HomePageProdDetails
              imagePosition="right"
              title={SECTION3[lang].title}
              text={SECTION3[lang].description[0]}
              text2={SECTION3[lang].description[1]}
              parallex={['-85px', '110px']}
              image={SECTION3[lang].img}
              lang={lang}
            ></HomePageProdDetails>
            <HomePageProdDetails
              imagePosition="left"
              title={SECTION4[lang].title}
              text={SECTION4[lang].description[0]}
              image={SECTION4[lang].img}
              parallex={['-150px', '150px']}
              lang={lang}
            >
              <div className="relative">
                <FilledCircle
                  top="-70px"
                  right={lang === 'ar' ? 'unset' : '6rem'}
                  left={lang === 'ar' ? '6rem' : 'unset'}
                  size="100px"
                  small_right="20%"
                  delay="1s"
                  duration="5s"
                />
              </div>
            </HomePageProdDetails>
          </div>
          <div className="section">
            <SetUpMelior lang={lang} />
          </div>
        </FlexContainer>
      </ParallaxProvider>
    </Layout>
  )
}
export default styled(IndexPage)`
  .imgXandO,
  .imgXandO1 {
    font-size: 0.7rem;
    font-weight: bold;
    position: absolute;
  }
  .imgXandO {
    bottom: 24.5%;
    right: 30.7%;
    &:before {
      content: '+';
      font-size: 0.7rem;
      font-weight: bold;
      position: absolute;
      top: -5.3rem;
      left: -7.2rem;
    }
    &:after {
      content: 'o';
      font-size: 0.6rem;
      font-weight: bold;
      position: absolute;
      top: -4.8rem;
      left: -7.5rem;
    }
  }

  @media (max-width: 375px) {
    .imgXandO {
      &:before {
        top: -4rem;
        left: -5.5rem;
      }
      &:after {
        top: -3.4rem;
        left: -5.8rem;
      }
    }
  }
  @media (max-width: 330px) {
    .imgXandO {
      &:before {
        top: -3rem;
        left: -4.5rem;
      }
      &:after {
        top: -2.4rem;
        left: -4.8rem;
      }
    }
  }
  .imgXandO1 {
    bottom: 44%;
    right: -1%;
    &::before {
      content: '+';
      font-size: 0.7rem;
      font-weight: bold;
      position: absolute;
      top: -5.2rem;
      left: -2.8rem;
    }
    &::after {
      content: 'o';
      font-size: 0.6rem;
      font-weight: bold;
      position: absolute;
      top: -4.6rem;
      left: -2.3rem;
    }
  }
  .img {
    width: 40%;
    height: auto;
    min-width: 200px;
    max-width: 450px;
    position: relative;
    &::before {
      content: '+';
      font-size: 0.7rem;
      font-weight: bold;
      position: absolute;
      top: 16.4%;
      left: 31.7%;
    }
    &::after {
      content: '+';
      font-size: 0.7rem;
      font-weight: bold;
      position: absolute;
      top: 52%;
      left: 1.5%;
    }
  }
  .actions {
    margin: 30px 0px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .relative {
    position: relative;
  }
  .section {
    position: relative;
    padding: 2rem 0 0;
    margin: 70px 0px;
    @media (max-width: 768px) {
      margin: 40px 0px;
    }
  }
  .smallScreenFullScreen {
    @media (max-width: 768px) {
      width: 90%;
      justify-content: center;
      margin: auto;
    }
  }
`
